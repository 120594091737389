.cms-not-found #breadcrumbs{
  display: none;
}

.found-page-container{
  background-image:url(/media/gtech/pages/error_pages/404-banner.jpg);
  background-repeat:no-repeat;
  min-height:39.85rem;
  background-size: cover;
  display: flex;
  padding-top: 8rem;
  width:100%;
  @include media-breakpoint-down(lg) { background-position: 50%;}
  @include media-breakpoint-down(md) { background-position: 40%;}
  @include media-breakpoint-down(sm) { background-position: 46%; min-height: 32rem;}
  h1{
    font-size:5rem;
    font-weight:600;
    color:#fff;
    opacity:0.18;
    line-height:0.5;
    letter-spacing: -0.05rem;
    @include media-breakpoint-down(sm) { font-size: 40px; line-height: 17px; letter-spacing: -0.7px;}
  }
  h2{
    font-size:3rem;
    font-weight:600;
    color:#fff;
    opacity:0.18;
    text-align: left;
    letter-spacing: -0.05rem;
    border-bottom:transparent;
    @include media-breakpoint-down(sm) { font-size: 25px; letter-spacing: -0.7px;}
  }
  p{
    font-size:1.75rem;
    color:#fff;
    line-height: 1.2;
    width:25.93rem;
    margin-bottom:2.875rem;
    text-align:left;
    letter-spacing: -0.045rem;
    @include media-breakpoint-down(sm) { font-size: 14px; width: 215px; line-height: 20px; letter-spacing: -1px;}
  }
  a{
    color:#fff;
    padding: 12px 40px;
    background-color:#95d700;
    font-size:22px;
    border-radius:5px;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0.01s;
    width: 82px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-transition: all 0.5s ease 0.01s;
    -webkit-transition: all 0.5s ease 0.01s;
    @include media-breakpoint-down(sm) { padding: 12px 20px; font-size: 14px;}
    &:hover{
      background:transparent;
      color: #95d700 !important;
      border: 2px solid #95d700;
    }
  }
}


.cms-not-found .container-fluid{
  padding-left:0;
  padding-right:0;
}


.cms-not-found .v2-pp-header-productbar-container{
  margin:15px 0 0 0 !important;
}

.cms-not-found .main{

  padding-bottom:0;
}

