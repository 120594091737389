/*Mixins*/

/*Product Pages Hero Section */

@mixin hero-block-container {
  background: $background-hero-block;
  h3 {
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: -0.01rem;
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 1rem;
    letter-spacing: -0.01rem;
    line-height: 1.6rem;
    @include media-breakpoint-between(sm,lg) {line-height: 1.4rem;}
  }
  .featured-tv{
    line-height: 1;
    font-size: 1.2rem;
  }
}

@mixin hero-block-container-mobile {
  width: 100%;
  background: $mobile-heroblock-background;
  h3 {
    font-size: 1.8rem;
    line-height: 1;
  }
}

@mixin top-buy-first-column {

  h2 {
    font-size: $product-header;
    @include media-breakpoint-between(md,lg) { font-size: 2.5rem;}
    line-height: 0.9;
    letter-spacing: -0.1rem;
  }
  h4 {
    letter-spacing: -0.01rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
    font-weight:lighter;
  }
}

@mixin top-buy-first-column-mobile {
  h2 {
    font-size: 1.8rem;
    line-height: 1;
    letter-spacing: -0.025rem;
  }
  h4 {
    letter-spacing: -0.01rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.01rem;
  }
}

@mixin top-buy-third-column {
  h1 {
    font-size: $title-buy-product;
    line-height:0.9;
    letter-spacing:-0.1rem;
    @include media-breakpoint-between(md,lg) { font-size: 2rem;}
  }
  .top-reviews span {
    color: $gtech-grey;
  }
  .min-star {
    font-size: 1.4rem;
  }
  .max-star {
    font-size: 0.8rem;
  }
  .top-star-review{
    vertical-align: sub;
    width:30%;
  }
  .reviews-brackets {
    text-decoration: underline;
  }
  h3 {
    font-size: 2.5rem;
    .smaller-price-item {
      font-size: 1.9rem;
    }
  }
  .upsell-container span {
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: -0.01rem;
  }
  .quantity-button {
    button{
      width:19.6rem;
      height: 3.5rem;
      padding-top: 0;
      padding-bottom: 0;
    }
    .add-to-cart{
      a{
        color:#fff;
        font-size: 1.8rem;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .border-img{
    border:2px solid $border-color;
  }
}

@mixin no-padding($side) {
  @if $side == 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding("left");
@include no-padding("right");
@include no-padding("top");
@include no-padding("bottom");
@include no-padding("all");


/* Navigation Promo Mixin */

@mixin right-promo($background-img) {
  background-image: url(/media/gtech/nav/#{$background-img});
  background-repeat: no-repeat;
  height: 100%;
  align-items: center;
  text-align: left;
  vertical-align: middle;
  padding: 0px 0px 0px 40px;

}

/*Placeholder*/

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

// Nov 23 Breakpoint Mixins - Harry M, Nick H

@mixin xs-only {
    @media only screen and (max-width: 639px) {
        @content
    }
}

@mixin sm-only {
    @media only screen and (min-width: 640px) and (max-width: 767px) {
        @content
    }
}

@mixin md-only {
    @media only screen and (min-width: 768px) and (max-width: 1023px) {
        @content
    }
}

@mixin lg-only {
    @media only screen and (min-width: 1024px) and (max-width: 1279px) {
        @content
    }
}

@mixin xl-only {
    @media only screen and (min-width: 1280px) and (max-width: 1919px) {
        @content
    }
}

@mixin xs-and-up {
    @media only screen and (min-width: 0) {
        @content
    }
}

@mixin sm-and-up {
    @media only screen and (min-width: 640px) {
        @content
    }
}

@mixin md-and-up {
    @media only screen and (min-width: 768px) {
        @content
    }
}

@mixin lg-and-up {
    @media only screen and (min-width: 1024px) {
        @content
    }
}

@mixin xl-and-up {
    @media only screen and (min-width: 1280px) {
        @content
    }
}

@mixin xxl-and-up {
    @media only screen and (min-width: 1920px) {
        @content
    }
}
